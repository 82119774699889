import * as R from 'ramda';
import { createPatch } from 'rfc6902';

import { reply1 } from './mock';
import { fetchCheckedJson, mockedAsync, appUrl } from '../utils/apiUtils';
import { entityTypes } from '../appConfig';
import { entityWith } from '../utils/dataUtils';

const entityAction = ({ entity, action = 'read', body = {} }) =>
  fetchCheckedJson(appUrl('conf', `/${R.toLower(entity)}/${action}`), {
    method: 'POST',
    // mode: 'no-cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

const paramDefaults = { skip: 0, limit: 10000 };
// const getReadParams = ({ filter, skip = 0, limit = 10000, projection }) => ({
//   filter, // mongdb filter objekt
//   skip, // int (skippa hur många)
//   limit, // int (max hur många)
//   projection, // mongodb projection objekt
//   // sort, // mongodb sort objekt.
// });

// const url = 'https://data-history.dev.faybl.online/read';
// const url = 'https://conf.<tenant>.faybl.online/<entity>/read';
export const createRecord = async ({ entity, id, ...props } = {}) => {
  // console.log('createRecord:', entity, id);
  const key = entityWith(entity, R.pipe(R.prop('required'), R.head))(entityTypes);
  const body = { ...props, [key]: id };
  // console.log('body:', body);
  const liveFn = () => entityAction({ action: 'create', entity, body });
  const rid = await mockedAsync(body, liveFn);
  // console.log(rid);
  return { rid, [key]: id };
};

export const readRecords = async ({ entity, ...params } = {}) => {
  // console.log('api.readRecords in:', entity, params);
  const body = { ...paramDefaults, ...params };
  /* eslint-disable no-underscore-dangle */
  const mockFn = () =>
    R.clone(
      params.limit === 1 && params.filter?._id
        ? [R.find(R.propEq('id', params.filter._id))(reply1)]
        : reply1,
    );
  /* eslint-enable no-underscore-dangle */
  const liveFn = () => entityAction({ entity, body });
  const records = await mockedAsync(mockFn, liveFn);
  // console.log('api.readRecords out:', records);
  return records;
};

export const updateRecord = async ({ entity, prevRecord, currentRecord, ...params } = {}) => {
  const { rid } = prevRecord;
  const patch = createPatch(prevRecord, currentRecord);
  const body = { rids: [rid], patch, ...params };
  const mock = R.clone(reply1);
  const liveFn = () => entityAction({ entity, action: 'update', body });
  const count = await mockedAsync(mock, liveFn);
  return count === 1 ? true : Promise.reject(Error('Record could not be updated'));
};

export const deleteRecords = async ({ entity, rids, ...params } = {}) => {
  // console.log('deleteRecords:', entity, rids);
  const body = { rids, ...params };
  const mock = rids;
  const liveFn = () => entityAction({ entity, action: 'delete', body });
  const count = await mockedAsync(mock, liveFn);
  // console.log('deleteRecords:', count);
  return count === rids.length ? rids : Promise.reject(Error('All records could not be deleted'));
};
