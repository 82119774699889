import { useContext, useReducer } from 'react';
import { useSelector } from '@xstate/react';
import * as R from 'ramda';
// import debounce from 'lodash.debounce';
import {
  Toolbar,
  AppBar,
  Button as MuiButton,
  ButtonGroup,
  CircularProgress,
  Typography,
  Box,
  Tooltip,
  Collapse,
} from '@mui/material';
import {
  styled,
  // useTheme
} from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import CodeIcon from '@mui/icons-material/Code';
import ReplayIcon from '@mui/icons-material/Replay';
import useKeypress from 'react-use-keypress';

import { DarkContext } from '../state/useSwitchableTheme';

// const AppBar = styled(MuiAppBar)(({ theme }) => ({
//   backgroundColor: theme.palette.action.disabledBackground,
// }));

const Button = styled(MuiButton)(() => ({
  '& .MuiButton-startIcon': { margin: 0 },
}));

const ToolButton = ({ title, disabled, ...props }) => {
  const { isDark } = useContext(DarkContext);
  const button = (
    <Button
      disabled={disabled}
      variant={isDark ? 'outlined' : 'contained'}
      color={isDark ? 'primary' : 'inherit'}
      {...props}
    />
  );
  return disabled ? button : <Tooltip title={title}>{button}</Tooltip>;
};

const reducer = (state, action) => (typeof action === 'boolean' ? action : !state);

const isLoadingSelector = ({ matches }) => R.none(matches)(['saved', 'unsaved']);
const errorSelector = ({ context: { error } }) => error;
const unsavedSelector = ({ matches }) => matches('unsaved');

export const RecordActionsBar = ({ record: { rid } = {}, mainService, entity }) => {
  const { isDark } = useContext(DarkContext);
  const { send } = mainService;
  const [deletable, dispatch] = useReducer(reducer, false);
  // const error = mainError || recordError;
  const error = useSelector(mainService, errorSelector);
  const isLoading = useSelector(mainService, isLoadingSelector);
  const unsaved = useSelector(mainService, unsavedSelector);

  // const editorRef = useRef();
  // const handleEditorDidMount = (editor) => (editorRef.current = editor);

  const handleFormat = () => send({ type: 'FORMAT' });
  const handleSave = () => send({ type: 'SAVE' });
  const handleDiscard = () => send({ type: 'LOAD', rid, entity });
  const handleLockToggle = () => dispatch();
  const handleDelete = () => {
    send({ type: 'DELETE', rid });
    dispatch(false);
  };
  useKeypress('F2', handleSave);

  return (
    <AppBar position="static">
      <Toolbar>
        <ButtonGroup size="large" aria-label="record actions button group" disabled={isLoading}>
          <ToolButton
            title="Save"
            startIcon={<UploadIcon />}
            onClick={handleSave}
            disabled={!unsaved}
          />
          <ToolButton
            title="Auto-format"
            startIcon={<CodeIcon />}
            onClick={handleFormat}
            disabled={!unsaved}
          />
          <ToolButton
            title="Discard changes"
            startIcon={<ReplayIcon />}
            onClick={handleDiscard}
            disabled={!unsaved}
          />
          <ToolButton
            title="Unlock delete record"
            startIcon={<DeleteOutlineIcon />}
            onClick={handleLockToggle}
            disabled={!rid}
          />
          <Collapse in={deletable} orientation="horizontal">
            <ToolButton
              title="Delete record"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              disabled={!rid}
              variant="contained"
            />
          </Collapse>
        </ButtonGroup>
        <Box sx={{ px: 2 }}>
          {isLoading ? (
            <CircularProgress color={isDark ? 'primary' : 'inherit'} size={32} sx={{ mt: 0.8 }} />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            ''
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
