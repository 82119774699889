import { useState, useMemo } from 'react';
import * as R from 'ramda';
import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
} from '@mui/material';

import { EnhancedTableHead } from './EnhancedTableHead';
import { EntityTableCell } from './EntityTableCell';
import { entityTypes } from '../appConfig';
import { entityWith } from '../utils/dataUtils';

const rowsPerPageOptions = [20, 50, 100];

const descendingComparator = (a, b, orderBy) =>
  b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0;

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const EntityTable = ({ entity, records, onRowClick }) => {
  const [orderProps, setOrderProps] = useState({ order: 'asc', orderBy: '' });
  const { order, orderBy } = orderProps;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderProps({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const metaProps = useMemo(() => entityWith(entity, R.prop('metaProps'))(entityTypes), [entity]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  const sortedPagedRecords = R.pipe(
    R.sort(getComparator(order, orderBy)),
    R.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  )(records);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            entity={entity}
            metaProps={metaProps}
          />
          <TableBody>
            {sortedPagedRecords.map((row) => (
              <TableRow
                key={row.rid}
                hover
                onClick={onRowClick(row.rid)}
                // role="checkbox"
                tabIndex={-1}
                sx={{ cursor: 'pointer' }}
              >
                {metaProps.map(({ prop }) => (
                  <EntityTableCell key={prop} value={row?.[prop]} />
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
