import React from 'react';
import { createRoot } from 'react-dom/client';
// import { inspect } from '@xstate/inspect';
import { App } from './App';

// inspect({
//   // options
//   url: 'https://statecharts.io/inspect',
//   // url: 'https://stately.ai/viz?inspect', // (default)
//   iframe: false, // open in new window
//   // iframe: document.querySelector('iframe[data-xstate]'),
// });

const root = createRoot(document.getElementById('root'));
root.render(<App />);
