import { useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import LightIcon from '@mui/icons-material/Light';
import { Box, Divider, AppBar, Toolbar, IconButton } from '@mui/material';

import { DarkContext } from '../state/useSwitchableTheme';

export const DrawerHeader = () => {
  const { isDark, setDark } = useContext(DarkContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <EditIcon fontSize="large" color={isDark ? 'primary' : 'default'} />
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="refetch" edge="end" onClick={() => setDark(!isDark)}>
          <LightIcon />
        </IconButton>
        <Divider />
      </Toolbar>
    </AppBar>
  );
};
