import { createMachine, assign, sendParent } from 'xstate';
import * as R from 'ramda';

import { readRecords, createRecord } from './api';
import { entityWith } from '../utils/dataUtils';
import { entityTypes } from '../appConfig';

const getEmptyStr = () => '';
const propListToProjection = R.reduce((acc, { prop }) => ({ ...acc, [prop]: 1 }), {});
const entityProjection = (entity) =>
  entityWith(entity, R.pipe(R.prop('metaProps'), propListToProjection))(entityTypes);

const getReadManyShallowParams = ({ entity }) => ({
  entity,
  filter: {},
  projection: entityProjection(entity),
});

export const entityMachineContext = {
  error: '',
  // entity: '',
};

const entityMachineConf = {
  predictableActionArguments: true,
  id: 'entity',
  initial: 'fetching',
  context: entityMachineContext,
  states: {
    idle: {
      on: {
        FETCH: 'fetching',
        CREATE_NEW: 'creatingRecord',
        DUPLICATE: {},
      },
    },
    fetching: {
      entry: ['clearError'],
      invoke: {
        src: 'fetcher',
        onError: { actions: 'setError', target: 'idle' },
        onDone: { actions: 'saveRecords', target: 'idle' },
      },
    },
    creatingRecord: {
      entry: ['clearError'],
      invoke: {
        src: 'createNew',
        onError: { actions: 'setError', target: 'idle' },
        onDone: { actions: 'addNew', target: 'fetching' },
      },
    },
  },
};

export const entityMachine = createMachine(entityMachineConf, {
  services: {
    fetcher: ({ entity }) => {
      // console.log({ entity });
      return readRecords(getReadManyShallowParams({ entity }));
    },
    createNew: ({ entity }, { id }) => createRecord({ entity, id }),
  },
  actions: {
    saveRecords: sendParent(({ entity }, { data = [] }) => ({
      type: 'RECORDS',
      entity,
      records: data,
    })),
    addNew: sendParent(({ entity }, { data }) => {
      // console.log({ entity });
      return {
        type: 'ADD_RECORD',
        record: data,
        entity,
      };
    }),
    setError: assign({ error: (ctx, { data: { message = '' } = {} }) => message }),
    clearError: assign({ error: getEmptyStr }),
  },
});
