import { createMachine, assign, spawn, send, actions } from 'xstate';
import { createContext } from 'react';
import * as R from 'ramda';
import Ajv from 'ajv';

import { readRecords, updateRecord, deleteRecords } from './api';
import { formatJson, entityWith, entityLinkedEntities, formatObject } from '../utils/dataUtils';
import { entityMachine, entityMachineContext } from './entityMachine';
import { entityTypes } from '../appConfig';

const { pure } = actions;

const ajv = new Ajv({ strict: false });
const getEmptyStr = () => '';

export const mainMachineContext = createContext();

const getReadOneParams = ({ entity, rid }) => ({
  entity,
  limit: 1,
  rids: [rid],
});

const context = {
  entities: R.map(({ title }) => ({ title, records: [] }))(entityTypes),
  entity: '',
  record: undefined,
  incomming: undefined,
  error: '',
  recordStr: '',
};

const mainMachineConf = {
  predictableActionArguments: true,
  id: 'main',
  initial: 'saved',
  context,
  on: {
    INIT_ENTITY: [{ cond: 'notInitialized', actions: 'initEntity' }, { actions: [] }],
    ADD_RECORD: { actions: 'addRecord' },
    RECORDS: { actions: 'saveRecords' },
  },
  states: {
    saved: {
      entry: ['updateSavedRecord'],
      on: {
        LOAD: { target: 'fetchingRecord' },
        CHANGE: { actions: 'updateContent', target: 'unsaved' },
        DELETE: 'deletingRecord',
      },
    },
    unsaved: {
      on: {
        LOAD: [
          { cond: 'nonEmpty', actions: 'setIncomming', target: 'confirmDiscard' },
          { target: 'fetchingRecord' },
        ],
        CHANGE: { actions: 'updateContent' },
        FORMAT: 'formattingRecord',
        SAVE: 'formattingRecord',
      },
    },
    confirmDiscard: {
      on: {
        YES: { target: 'fetchingRecord' },
        NO: { target: 'unsaved' },
      },
    },
    formattingRecord: {
      invoke: {
        src: 'formatRecord',
        onError: { actions: 'setError', target: 'unsaved' },
        onDone: [
          { cond: 'isSave', actions: 'updateContent', target: 'savingRecord' },
          { actions: 'updateContent', target: 'unsaved' },
        ],
      },
    },
    savingRecord: {
      invoke: {
        src: 'saveRecordServer',
        onError: { actions: 'setError', target: 'unsaved' },
        onDone: { actions: 'signalFetch', target: 'saved' },
      },
    },
    fetchingRecord: {
      entry: ['initLinkedDatasets', 'fetchLinkedDatasets'],
      invoke: {
        src: 'fetchRecord',
        onError: { actions: 'setError', target: 'unsaved' },
        onDone: { actions: 'loadContent', target: 'saved' },
      },
    },
    deletingRecord: {
      invoke: {
        src: 'deleteRecord',
        // onError: { actions: ['setError', 'emptyContent', 'signalFetch'], target: 'unsaved' },
        onError: { actions: 'setError', target: 'unsaved' },
        onDone: { actions: ['emptyContent', 'signalFetch'], target: 'saved' },
      },
    },
  },
};

const adjustEntity = (entity, fn) =>
  R.converge(R.adjust(R.__, fn), [R.findIndex(R.propEq('title', entity)), R.identity]);

const spawnEntityMachine = (entity) =>
  spawn(entityMachine.withContext({ ...entityMachineContext, entity }), entity);

// const addSpawnedEntityMachine = (entity, entities) =>
//   R.adjust(
//     R.findIndex(R.propEq('title', entity))(entities),
//     R.assoc('ref', spawnEntityMachine(entity)),
//   )(entities);

export const mainMachine = createMachine(mainMachineConf, {
  services: {
    fetchRecord: async ({ incomming }, { entity, rid }) => {
      // console.log('fetchRecord:', incomming, entity, rid);
      const record = await R.pipe(
        getReadOneParams,
        readRecords,
        R.andThen(R.head),
      )(incomming ?? { entity, rid });
      const [error, str, obj] = formatObject(record);
      if (error) return Promise.reject(error);
      // console.log('fetchRecord:', str, obj);
      return { entity, record: obj, recordStr: str };
    },
    saveRecordServer: async ({ entity, error, record }, { data: { newRecord } }) => {
      // console.log('saveRecordServer:', record, newRecord);
      return error
        ? Promise.reject(Error(error))
        : {
            updateReply: await updateRecord({
              prevRecord: record,
              currentRecord: newRecord,
              entity,
            }),
            newRecord,
          };
    },
    formatRecord: async ({ recordStr, entity }, { type }) => {
      const [error, str, obj] = formatJson(recordStr);
      if (error) return Promise.reject(error);
      // console.log('formatRecord:', type, entity, str, '| newRecord:', obj);
      const valid = ajv.validate(entityWith(entity)(entityTypes), obj);
      if (!valid) {
        console.log(ajv.errors);
        const err = Error(
          R.pipe(
            R.map(({ instancePath, message }) => `${instancePath || 'Entity'} ${message}`),
            R.join(' | '),
          )(ajv.errors),
        );
        return Promise.reject(err);
      }
      return { newRecordStr: str, newRecord: obj, isSave: type === 'SAVE' };
    },
    deleteRecord: async ({ entity }, { rid }) => {
      // console.log({ entity });
      return deleteRecords({ entity, rids: [rid] });
    },
  },

  guards: {
    nonEmpty: ({ recordStr }) => {
      // console.log('nonEmpty:', recordStr, ev);
      return Boolean(recordStr);
    },
    // nonEmpty: ({ recordStr }) => Boolean(recordStr),
    notInitialized: ({ entities }, { entity }) =>
      entityWith(entity, R.complement(R.has)('ref'))(entities),
    isSave: (_, { data: { isSave } }) => {
      // console.log('isSave:', isSave);
      return isSave;
    },
  },

  actions: {
    initLinkedDatasets: assign({
      entities: ({ entities }, { entity }) => {
        // console.log('initLinkedDatasets:', entity); // Asset
        const linkedDatasets = entityLinkedEntities(entity)(entityTypes);
        // console.log('initLinkedDatasets, linkedDatasets:', linkedDatasets);
        const resultingEntities = R.map((item) =>
          !item.ref && R.includes(item.title)(linkedDatasets)
            ? { ...item, ref: spawnEntityMachine(item.title) }
            : item,
        )(entities);
        // console.log({ resultingEntities });
        return resultingEntities;
      },
    }),
    fetchLinkedDatasets: pure(({ entities }, { entity }) => {
      const linkedDatasets = entityLinkedEntities(entity)(entityTypes);
      // console.log('Sending fetch signals to:', linkedDatasets);
      return R.into(
        [],
        R.pipe(
          R.filter(({ ref, title }) => ref && R.includes(title)(linkedDatasets)),
          R.map(({ ref }) => send({ type: 'FETCH' }, { to: ref })),
        ),
      )(entities);
    }),
    signalFetch: send({ type: 'FETCH' }, { to: ({ entity }) => entity }),
    initEntity: assign({
      entities: ({ entities }, { entity }) =>
        adjustEntity(entity, R.assoc('ref', spawnEntityMachine(entity)))(entities),
      // entities: ({ entities }, { entity }) => addSpawnedEntityMachine(entity, entities),
    }),
    setError: assign({ error: (ctx, { data: { message = '' } = {} }) => message }),
    setIncomming: assign({
      incomming: (ctx, { entity, rid }) => {
        // console.log('setIncomming:', entity, rid);
        return { entity, rid };
      },
      // incomming: (ctx, { entity, rid }) => ({ entity, rid }),
    }),
    updateSavedRecord: assign({
      incomming: () => undefined,
      record: ({ record }, { data: { newRecord } = {} }) => newRecord ?? record,
    }),
    emptyContent: assign({
      record: () => undefined,
      error: getEmptyStr,
      recordStr: getEmptyStr,
    }),
    loadContent: assign({
      recordStr: (ctx, { data: { recordStr } }) => recordStr,
      record: (ctx, { data: { record } }) => record,
      entity: (ctx, { data: { entity } }) => entity,
      error: getEmptyStr,
    }),
    updateContent: assign({
      recordStr: (ctx, { data: { newRecordStr } }) => {
        // console.log('updateContent:', newRecordStr, typeof newRecordStr);
        return newRecordStr;
      },
      error: getEmptyStr,
    }),
    saveRecords: assign({
      entities: ({ entities }, { entity, records = [] }) => {
        // console.log({ entities });
        return adjustEntity(entity, R.assoc('records', records))(entities);
        // return R.adjust(
        //   R.findIndex(R.propEq('title', entity))(entities),
        //   R.assoc('records', records),
        // )(entities);
      },
    }),
    addRecord: assign({
      entities: ({ entities }, { entity, record }) =>
        adjustEntity(entity, R.evolve({ records: R.prepend(record) }))(entities),
      // R.adjust(
      //   R.findIndex(R.propEq('title', entity))(entities),
      //   R.evolve({ records: R.prepend(record) }),
      // )(entities),
    }),
  },
});

/**
TODO:
=====

 */
