import { useMemo, useCallback } from 'react';
// import Form from '@rjsf/core'; // eslint-disable-line
import Form from '@rjsf/material-ui/v5'; // eslint-disable-line
import { Box } from '@mui/material';
import { useSelector } from '@xstate/react';
import * as R from 'ramda';

import { entityWith, evolveSelectedProps } from '../utils/dataUtils';
// import { entityTypes } from '../appConfig';

const entitiesSelector = ({ context: { entities } }) => entities;

const textareaWidgetConf = {
  'ui:widget': 'textarea',
  'ui:options': { rows: 5 },
};

export const SchemaForm = ({ mainService, schema, record, ...rest }) => {
  const entities = useSelector(mainService, entitiesSelector);

  const props = R.toPairs(schema.properties);

  const toEnumArray = R.reduce(
    (acc, [prop, { link, type }]) => (link && type === 'object' ? [...acc, prop] : acc),
    [],
  )(props);

  const anytypeProps = R.reduce(
    (acc, [prop, { type }]) => (type === undefined ? [...acc, prop] : acc),
    [],
  )(props);

  const uiSchema = R.pipe(
    R.map((prop) => [prop, textareaWidgetConf]),
    R.fromPairs,
    R.mergeLeft({ rid: { 'ui:disabled': true } }),
  )(anytypeProps);

  const { updatedRecord: adjustedRecord, updatedSchema: adjustedSchema } = useMemo(() => {
    const toEnum = R.reduce(
      (acc, [prop, { link, type }]) => (link && type !== 'object' ? [...acc, [prop, link]] : acc),
      [],
    )(props);

    const updatedSchema = R.over(
      R.lensProp('properties'),
      R.pipe(
        R.reduce(
          (acc, prop) => {
            return R.evolve({ [prop]: R.assoc('type', 'string') })(acc);
          },
          R.__,
          anytypeProps,
        ),
        R.reduce(
          (acc, [prop, link]) => {
            // console.log({ acc });
            const records = entityWith(link, R.prop('records'))(entities);
            const enumData = R.pluck('id')(records);
            return R.evolve({ [prop]: R.assoc('enum', [undefined, ...enumData]) })(acc);
          },
          R.__,
          toEnum,
        ),
        R.reduce(
          (acc, prop) => {
            const records = entityWith(
              R.path(['properties', prop, 'link'])(schema),
              R.prop('records'),
            )(entities);
            const enumData = R.pluck('id')(records);
            return R.evolve({
              [prop]: R.mergeLeft({
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    name: { type: 'string' },
                    rid: { type: 'string', enum: [undefined, ...enumData] },
                  },
                },
              }),
            })(acc);
          },
          R.__,
          toEnumArray,
        ),
      ),
    )(schema);

    const updatedRecord = evolveSelectedProps(
      R.pipe(
        R.toPairs,
        R.map(([name, rid]) => ({ [name]: rid })),
      ),
    )(record, toEnumArray);

    return { updatedRecord, updatedSchema };
  }, [schema, record, entities, props, toEnumArray, anytypeProps]);

  // console.log(entities, schema, adjustedSchema, adjustedRecord);

  const handleSubmit = useCallback(
    ({ formData }) => {
      console.log('formData raw:', formData);
      const schemaRealignedRecord = evolveSelectedProps(
        R.pipe(R.map(R.props(['name', 'rid'])), R.fromPairs),
      )(formData, toEnumArray);
      console.log('formData processed:', schemaRealignedRecord);
    },
    [toEnumArray],
  );

  return (
    <Box p={4}>
      <Form
        schema={adjustedSchema}
        uiSchema={uiSchema}
        formData={adjustedRecord}
        onSubmit={handleSubmit}
        {...rest}
      />
    </Box>
  );
};
