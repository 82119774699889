// import { useEffect } from 'react';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
// import { inspect } from '@xstate/inspect';

import { useSwitchableTheme, DarkContext } from './state/useSwitchableTheme';
import { Layout } from './ui/Layout';
import { getThemeConfig } from './appConfig';

/* eslint-disable jest/require-hook */
// inspect({
//   url: 'https://statecharts.io/inspect',
//   iframe: false,
//   // iframe: () => document.querySelector('iframe[data-xstate]'),
// });
/* eslint-enable jest/require-hook */

export const App = () =>
  // {
  // machineDevTools = true
  // },
  {
    const { theme, darkCtx } = useSwitchableTheme(getThemeConfig);

    // useEffect(
    //   () =>
    //     void (
    //       machineDevTools &&
    //       inspect({
    //         url: 'https://statecharts.io/inspect',
    //         iframe: false,
    //         // iframe: () => document.querySelector('iframe[data-xstate]'),
    //       })
    //     ),
    //   [machineDevTools],
    // );

    return (
      <DarkContext.Provider value={darkCtx}>
        <GlobalStyles
          styles={{
            body: { overflow: 'hidden' },
            '::-webkit-scrollbar ': {
              width: 5,
            },
            '::-webkit-scrollbar-track ': {
              background: theme.palette.background.paper,
            },
            '::-webkit-scrollbar-thumb ': {
              background: theme.palette.action.disabled,
              borderRadius: 2.5,
            },
          }}
        />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout />
        </ThemeProvider>
      </DarkContext.Provider>
    );
  };
