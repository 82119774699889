import { useContext } from 'react';
import { useSelector } from '@xstate/react';
// import debounce from 'lodash.debounce';
import {
  // AppBar,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { mainMachineContext } from '../state/mainMachine';

const Button = styled(MuiButton)(() => ({
  '& .MuiButton-startIcon': { margin: 0 },
}));

// const incommingSelector = ({ context: { incomming } }) => incomming;
// const entitySelector = ({ context: { entity } }) => entity;
const confirmOpenSelector = ({ matches }) => matches('confirmDiscard');

export const EntityDialog = () => {
  // const { isDark } = useContext(DarkContext);
  const mainService = useContext(mainMachineContext);
  const { send } = mainService;
  // const incomming = useSelector(mainService, incommingSelector);
  const confirmOpen = useSelector(mainService, confirmOpenSelector);
  // const entity = useSelector(mainService, entitySelector);

  const negativeConfirm = () => send({ type: 'NO' });
  const positiveConfirm = () => send({ type: 'YES' });

  return (
    <Dialog
      open={confirmOpen}
      onClose={negativeConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Discard unsaved draft?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to discard your unsaved changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={positiveConfirm}>Yes</Button>
        <Button variant="contained" onClick={negativeConfirm} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
