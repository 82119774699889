import { useMemo, useState } from 'react';
import * as R from 'ramda';
import { useActor } from '@xstate/react';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Divider,
  Toolbar,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  TextField,
  Tooltip,
  AccordionDetails as MuiAccordionDetails,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';

import { EntityTable } from './EntityTable';
import { entityWith } from '../utils/dataUtils';

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

export const EntityList = ({ entity, service, entities, sendParent }) => {
  const theme = useTheme();
  const records = entityWith(entity, R.prop('records'))(entities);
  const [
    {
      context: { error },
      matches,
    },
    send,
  ] = useActor(service);

  const { register, handleSubmit, watch } = useForm();
  const [shouldCreate, setShouldCreate] = useState(false);
  const field = watch('field');

  const createNew = (data) => shouldCreate && send({ type: 'CREATE_NEW', id: data.field });
  const handleEntityClick = (rid) => () => sendParent({ type: 'LOAD', rid, entity });

  const filteredRecords = useMemo(
    () =>
      shouldCreate
        ? records
        : R.filter(R.pipe(R.values, R.any(R.test(new RegExp(field, 'i')))))(records),
    [field, shouldCreate, records],
  );
  const isLoading = matches('fetching');

  return (
    <AccordionDetails>
      <Divider />
      <Box sx={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(0.5) }}>
        {isLoading ? (
          <div>
            <CircularProgress size="small" sx={{ width: 28, mt: 0.75, mr: -0.75 }} />
          </div>
        ) : (
          <Tooltip title="Refetch">
            <IconButton aria-label="refetch" edge="end" onClick={() => send('FETCH')}>
              <SyncIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <form onSubmit={handleSubmit(createNew)}>
        <Toolbar disableGutters variant="dense" sx={{ p: 1, px: 2 }}>
          <TextField
            margin="dense"
            size="small"
            variant="outlined"
            id="field"
            label={shouldCreate ? 'ID' : 'Filter...'}
            defaultValue=""
            fullWidth
            inputProps={register('field')}
            sx={{ margin: 0, marginRight: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldCreate}
                onChange={(ev) => setShouldCreate(ev.target.checked)}
              />
            }
            label="Create"
          />
          <IconButton edge="end" aria-label="submit-field" type="submit">
            {shouldCreate ? <AddIcon /> : <FilterListIcon />}
          </IconButton>
        </Toolbar>
      </form>
      {!!error && (
        <Toolbar
          disableGutters
          variant="dense"
          sx={{ px: 2, borderLeft: 4, borderColor: 'error.main' }}
        >
          <Typography>{error}</Typography>
        </Toolbar>
      )}
      <EntityTable entity={entity} records={filteredRecords} onRowClick={handleEntityClick} />
    </AccordionDetails>
  );
};
