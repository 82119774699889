// import * as R from 'ramda';
import { entitySchemas } from 'iotdatamodel';

import { adjustSchemas } from './utils/dataUtils';

export const isLocalhost = () => window.location.host.startsWith('localhost');

export const testEndpoint = 'https://conf.core.faybl.online';
export const failChance = 0.2;

export const localMode = 'test'; // test, mock
export const mode = isLocalhost() ? localMode : 'prod'; // mock, test, prod

export const getThemeConfig = () => ({
  palette: {
    primary: { main: '#8bc34a' },
  },
});

console.log(entitySchemas);
export const entityTypes = adjustSchemas(entitySchemas);

console.log({ entityTypes });
// console.log(JSON.stringify(entityTypes, undefined, 2));
