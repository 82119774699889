import { useContext } from 'react';
import Editor from '@monaco-editor/react';

import { DarkContext } from '../state/useSwitchableTheme';

export const JsonEditor = ({ recordStr, setRecordStr, entity, schema }) => {
  const { isDark } = useContext(DarkContext);

  const setup = (editor, monaco) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          // uri: ``, // id of the first schema
          fileMatch: ['*'], // associate with our model
          schema,
        },
      ],
    });
  };

  return (
    <Editor
      height="100%"
      theme={isDark ? 'vs-dark' : ''}
      path={entity}
      language="json"
      defaultValue=""
      // fontSize="42px"
      value={recordStr}
      onChange={setRecordStr}
      onMount={setup}
      // autoIndent
      // formatOnPaste
      // formatOnType
    />
  );
};
