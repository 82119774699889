import { forwardRef } from 'react';
import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { useMachine } from '@xstate/react';
import { useInterpret } from '@xstate/react';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import { mainMachine, mainMachineContext } from '../state/mainMachine';
import { DrawerHeader } from './DrawerHeader';
import { EntityStack } from './EntityStack';
import { EntityPage } from './EntityPage';

const ThemedSplitter = forwardRef((props, ref) => <SplitterLayout {...props} ref={ref} />);

const Main = styled('main')({ height: '100vh' });

export const Layout = () => {
  const mainService = useInterpret(mainMachine /* , { devTools: true } */);

  return (
    <mainMachineContext.Provider value={mainService}>
      <ThemedSplitter>
        <Box sx={{ height: '100vh' }}>
          <DrawerHeader />
          <Divider />
          <EntityStack />
        </Box>
        <Main>
          <EntityPage />
        </Main>
      </ThemedSplitter>
    </mainMachineContext.Provider>
  );
};
