import { useState } from 'react';
// import * as R from 'ramda';
import { TableCell, Chip as MuiChip, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { copyToClipboard } from '../utils/apiUtils';

const Chip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const EntityTableCell = ({ value }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toClipboard = (ev) => {
    ev.stopPropagation();
    if (value && typeof value === 'string') {
      if (copyToClipboard(value)) {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 1000);
      }
    }
  };

  const handleTooltipClose = () => setShowTooltip(false);

  return (
    <TableCell>
      <Tooltip
        title="Copied"
        placement="right"
        PopperProps={{ disablePortal: true }}
        onClose={handleTooltipClose}
        open={showTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <Chip label={value ?? '-'} size="small" onClick={toClipboard} />
      </Tooltip>
    </TableCell>
  );
};
