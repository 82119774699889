import stringify from 'json-stringify-pretty-compact';
import * as R from 'ramda';
import { bi } from 'tri-fp';
import jsonParse from 'json-parse-even-better-errors';
// import jsonParse from 'parse-json';
// import jsonParse from 'json-parse-helpfulerror';
import sortKeys from 'sort-keys';
// import { format } from 'prettier/standalone';
// import { parsers } from 'prettier/parser-babel';

export const ARR = Object.freeze([]);
/* eslint-disable no-null/no-null */
export const OBJ = Object.freeze(Object.create(null));
/* eslint-enable no-null/no-null */
export const FUNC = Function.prototype;

const safeJsonParse = bi(jsonParse);
export const formatStringify = (record) => stringify(record, { maxLength: 100 });

// export const prettify = (str) =>
//   format(str, {
//     // filepath: parser,
//     parser: parsers['json-stringify'].parse,
//   });

// const quoteTheUnquoted = R.replace(
//   /([{,]\s*)([a-zA-Z_$@!%&<>=+:;][^":,}\]]*)/g,
//   (str, pre, key) => {
//     const val = key.trim();
//     return val && !['true', 'false', 'null'].includes(val) ? `${pre}"${val}"` : str;
//   },
// );
// const removeLastComma = R.replace(/([{,]\s*)([a-zA-Z_$@!%&<>=+:;][^":,}\]]*)/g, (str, pre, key) => {
//   const val = key.trim();
//   return val && !['true', 'false', 'null'].includes(val) ? `${pre}"${val}"` : str;
// });

export const formatObject = (obj) => {
  const record = sortKeys(obj, { deep: true });
  // console.log(error, obj);
  return [undefined, formatStringify(record), record];
};

export const formatJson = (jsonStr) => {
  // console.log(jsonStr, R.replace(/\n/g, '')(jsonStr));
  const [error, obj] = safeJsonParse(R.replace(/\n/g, '')(jsonStr));
  if (error) return [error, undefined, obj];
  return formatObject(obj);
};

// export const formatJson = (jsonStr) => {
//   console.log(jsonStr);
//   console.log(prettify(jsonStr));
//   return [undefined, prettify(jsonStr)];
// };

export const capitalize = (s) => `${s[0].toUpperCase()}${s.slice(1)}`;

export const entityWith = (entity, fn = R.identity) =>
  R.pipe(R.find(R.propEq('title', entity)), fn);

export const stringToColor = (str) => {
  /* eslint-disable no-bitwise, fp/no-loops, functional/no-loop-statement, functional/no-let, fp/no-mutation */
  let hash = 0;
  let i;
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise, fp/no-loops, functional/no-loop-statement, functional/no-let, fp/no-mutation */
  return color;
};

export const stringAvatar = (id) => {
  return {
    sx: {
      bgcolor: stringToColor(id),
    },
    children: `${id[0]}${id[1]}`,
  };
};

const prepareStringProps = R.pipe(
  R.filter(
    R.either(
      R.propEq('type', 'string'),
      R.propSatisfies(R.pipe(R.defaultTo([]), R.includes('string')), 'type'),
    ),
  ),
  R.keys,
  R.map((prop) => ({ prop, label: capitalize(prop) })),
);

// const prepareLinkedEntities = R.pipe(R.values, R.pluck('link'), R.filter(R.identity));
const prepareLinkedEntities = R.pipe(
  R.toPairs,
  R.reduce((acc, [prop, { link }]) => link && { ...acc, [prop]: link }, {}),
);

export const adjustSchemas = R.map((schema) => {
  const { properties = {} } = schema;
  return R.evolve({ required: R.without(['rid']) })({
    ...schema,
    metaProps: prepareStringProps(properties),
    links: prepareLinkedEntities(properties),
  });
});

export const entityLinkedEntities = (entity) =>
  entityWith(entity, R.pipe(R.prop('links'), R.values));

export const evolveSelectedProps = (evolver) =>
  R.reduce((acc, prop) =>
    R.evolve({
      [prop]: evolver,
    })(acc),
  );
