// import * as R from 'ramda';
// import { random } from 'rambdax';
import isFunction from 'lodash.isfunction';
import { triAsync } from 'tri-fp';

import { testEndpoint, mode, failChance } from '../appConfig';

const testMode = mode === 'test';
const mockMode = mode === 'mock';

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');

export const stringifyParams =
  ({ pairSeparator = '=', itemsSeparator = '&' } = {}) =>
  (params) =>
    `${Object.entries(params)
      .map((pair) => pair.join(pairSeparator))
      .join(itemsSeparator)}`;

export const urlParams = stringifyParams();

export const appUrl = (app, relativePath = '', params = undefined) => {
  const prodEndpoint = `${getProtocol()}//${getHost().replace(/[^.]+/, app)}`;
  const relativeEnding = `${relativePath}${params ? `?${urlParams(params)}` : ''}`;
  return testMode ? `${testEndpoint}${relativeEnding}` : `${prodEndpoint}${relativeEnding}`;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const mocked = (mock) => (fn) => mockMode ? (isFunction(mock) ? mock() : mock) : fn();

export const mockedAsync = async (mock, fnP, { delay = 1000, okChance = 1 - failChance } = {}) =>
  !mockMode
    ? fnP()
    : sleep(delay).then(() => {
        // console.log({ mock, rand: random(0, 4) });
        return Math.random() > okChance
          ? Promise.reject(
              Error(
                'Failed with a long long long message that may very possibly span multiple lines',
              ),
            )
          : isFunction(mock)
          ? mock()
          : mock;
      });

// let uglyIdForLogging = 0; // eslint-disable-line

export const fetchCheckedJson = async (url, { refreshTokenFunc, ...options } = {}) => {
  // const msgId = uglyIdForLogging;
  // uglyIdForLogging += 1; // eslint-disable-line
  // console.log(`Will fetch [${msgId}]:`, url);

  const res = await fetch(url, { credentials: 'include', ...options });
  const { ok, status, statusText, body } = res;
  // console.log(`Response [${msgId}]:`, res);
  const json = res.json ? await res.json() : body;
  if (!ok) {
    if (status === 401 && refreshTokenFunc) {
      //  && json.error === TOKEN_IS_EXPIRED
      return refreshTokenFunc().then(() => fetchCheckedJson(url, options));
    }
    const { error: errorText, message } = json;
    const error = Error(`Fetch failed: ${status} ${errorText} ${message ? ` - ${message}` : ''}`);
    error.response = { status, statusText }; // eslint-disable-line
    return Promise.reject(error);
  }
  // console.log(`JSON data [${msgId}]:`, JSON.stringify(json, null, 2)); // eslint-disable-line
  return json;
};

export const copyToClipboard = async (text) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported');
    return false;
  }
  const [error] = await triAsync(navigator.clipboard.writeText(text));
  if (error) {
    console.warn('Copy failed', error);
    return false;
  }
  return true;
};
