import { useContext } from 'react';
// import * as R from 'ramda';
import { useSelector } from '@xstate/react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { mainMachineContext } from '../state/mainMachine';
// import { entityTypes } from '../appConfig';
import { EntityList } from './EntityList';
// import { entityWith } from '../utils/dataUtils';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const entitiesSelector = ({ context: { entities } }) => entities;

export const EntityStack = () => {
  // console.log('EntityStack entities:', entities);
  const mainService = useContext(mainMachineContext);
  const { send } = mainService;
  const entities = useSelector(mainService, entitiesSelector);

  const handlePanelToggle = (entity) => () => send('INIT_ENTITY', { entity });

  return entities.map(({ title, ref }) => (
    <Accordion key={title} onChange={handlePanelToggle(title)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      {ref && <EntityList entity={title} service={ref} entities={entities} sendParent={send} />}
    </Accordion>
  ));
};
