import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export const EnhancedTableHead = ({ order, orderBy, onRequestSort, metaProps }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {metaProps.map(({ prop, label }) => (
          <TableCell key={prop} sortDirection={orderBy === prop ? order : false}>
            <TableSortLabel
              active={orderBy === prop}
              direction={orderBy === prop ? order : 'asc'}
              onClick={createSortHandler(prop)}
            >
              {label}
              {orderBy === prop ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : (
                false
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
