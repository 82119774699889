/* eslint-disable max-lines */
// import * as R from 'ramda';
// import { addSeconds } from 'date-fns/fp';
// import { random } from 'rambdax';

// const nextPair = ([date, y]) => [addSeconds(random(50, 600), date), y + random(0, 8) - 4];
// const nextStrPair = ([date]) => [
//   addSeconds(random(50, 600), date),
//   ['banan', 'äpple', 'kiwi', 'satsumas', 'apelsin', 'päron'][random(0, 5)],
// ];

// export const randData = (nextFn = nextPair, init = [[new Date(), 50]]) =>
//   R.pipe(
//     R.defaultTo(1000),
//     R.range(0),
//     R.reduce(([prev, ...rest]) => [nextFn(prev), prev, ...rest], init),
//     R.reverse,
//     R.map(R.zipObj(['timestamp', 'data'])),
//   );
//
// const largeDatasetCount = 2000;
// export const reply3 = { count: largeDatasetCount, data: randData()(largeDatasetCount) };
//
// export const reply4 = {
//   count: largeDatasetCount,
//   data: randData(nextStrPair, [[new Date(), 'persika']])(largeDatasetCount),
// };

export const reply1 = [
  {
    rid: '873456',
    id: 'r1',
    name: 'Rule 1',
    active: true,
    type: 'thebest',
    triggered: {
      cooldown: 1500,
      resetCondition: true,
      resetAction: ['console.log', '`RULE-1 RESET with data:`', '$payload'],
    },
    condition: ['R.lt', 0.5, '$payload'],
    action: ['console.log', '`RULE-1 triggered with data:`', '$payload'],
  },
  {
    rid: '94853',
    id: 'MM-rule-3',
    name: 'Multi-machine 3',
    active: true,
    type: 'theworst',
    triggered: {
      cooldown: 1500,
      resetCondition: true,
      resetAction: ['console.log', '`RULE-1 RESET with data:`', '$payload'],
    },
    condition: ['R.lt', 0.5, '$payload'],
    action: ['console.log', '`RULE-1 triggered with data:`', '$payload'],
  },
  {
    rid: '893745',
    id: 'gY',
    name: 'Great Yokto',
    active: true,
    triggered: {
      cooldown: 1500,
      resetCondition: true,
      resetAction: ['console.log', '`RULE-1 RESET with data:`', '$payload'],
    },
    condition: ['R.lt', 0.5, '$payload'],
    action: ['console.log', '`RULE-1 triggered with data:`', '$payload'],
  },
  {
    rid: '198167',
    id: '8718294',
    name: 'Just numbers',
    active: true,
    triggered: {
      cooldown: 1500,
      resetCondition: true,
      resetAction: ['console.log', '`RULE-1 RESET with data:`', '$payload'],
    },
    condition: ['R.lt', 0.5, '$payload'],
    action: ['console.log', '`RULE-1 triggered with data:`', '$payload'],
  },
  {
    rid: '3984756',
    id: 'my-rule-or-the-highway',
    name: 'Highway',
    active: true,
    triggered: {
      cooldown: 1500,
      resetCondition: true,
      resetAction: ['console.log', '`RULE-1 RESET with data:`', '$payload'],
    },
    condition: ['R.lt', 0.5, '$payload'],
    action: ['console.log', '`RULE-1 triggered with data:`', '$payload'],
  },
];
