import { useContext, useReducer } from 'react';
import { useSelector } from '@xstate/react';
import {
  Tabs,
  Tab,
  Toolbar,
  // Paper, Typography,
  // Collapse,
} from '@mui/material';
import * as R from 'ramda';
// import debounce from 'lodash.debounce';
// import { styled } from '@mui/material/styles';

import { entityWith } from '../utils/dataUtils';
import { mainMachineContext } from '../state/mainMachine';
import { JsonEditor } from './JsonEditor';
import { SchemaForm } from './SchemaForm';
import { RecordActionsBar } from './RecordActionsBar';
import { EntityDialog } from './EntityDialog';
import { entityTypes } from '../appConfig';

const recordSelector = ({ context: { record } }) => record;
const recordStrSelector = ({ context: { recordStr } }) => recordStr;
const entitySelector = ({ context: { entity } }) => entity;

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const reducer = (state, action) => action;

export const EntityPage = () => {
  // const { isDark } = useContext(DarkContext);
  const mainService = useContext(mainMachineContext);
  const { send } = mainService;
  const record = useSelector(mainService, recordSelector);
  const recordStr = useSelector(mainService, recordStrSelector);
  const entity = useSelector(mainService, entitySelector);
  const schema = entityWith(entity, R.identity)(entityTypes);

  // console.log(record, recordStr, entity, schema);

  // useEffect(() => void send({ type: 'RESET', record }), [record, send]);

  const [value, dispatch] = useReducer(reducer, 0);
  const handleChange = (event, newValue) => dispatch(newValue);

  return (
    <>
      <RecordActionsBar mainService={mainService} record={record} entity={entity} />
      <Toolbar variant="dense">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="JSON" {...a11yProps(0)} />
          <Tab label="FORM" {...a11yProps(1)} />
        </Tabs>
      </Toolbar>
      {/* <Collapse direction="vertical" in={value === 0} sx={{ height: '100%' }}> */}
      {value === 0 && (
        <JsonEditor
          key={entity}
          setRecordStr={(newRecordStr) => send({ type: 'CHANGE', data: { newRecordStr } })}
          recordStr={recordStr}
          entity={entity}
          schema={schema}
        />
      )}
      {value === 1 && schema && (
        <SchemaForm
          schema={schema}
          entity={entity}
          mainService={mainService}
          record={record}
          liveValidate
        />
      )}
      {/* </Collapse> */}
      {/* <Collapse direction="vertical" in={value === 1}>
        <Paper>
          <Typography>TEST</Typography>
        </Paper>
      </Collapse> */}
      <EntityDialog />
    </>
  );
};
